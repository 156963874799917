
import Vue from "vue";

export default Vue.extend({
    name: "ReportSubmittedModal",
    props: {
        isLoading: { type: Boolean, default: false },
        reviewer: { type: Object, required: true, default: () => ({}) },
    },
    computed: {
        user(): any {
            const [firstName, lastName] = this.reviewer?.companyReviewerDetails?.fullname.split(" ") ?? [];
            return { firstName, lastName };
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-report-submitted");
        },
        reassignReviewer() {
            const { id } = this.$route.params;
            this.closeModal();
            this.$bvModal.show(`modal-reassign-reviewer-${id}`);
        },
        goBackToReportPage() {
            this.closeModal();
            const { id } = this.$route.params;
            this.$router.replace({ name: "reports.view", params: { id } });
        },
    },
});
