var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-2 mt-4"
  }, [_vm.question.header ? _c('span', {
    staticClass: "d-block question-name"
  }, [_vm._v(_vm._s(_vm.question.header))]) : _vm._e(), _c('h5', {
    staticClass: "question-title"
  }, [_vm._v(" " + _vm._s(_vm.question.uniqueName) + " "), _vm.question.isRequired ? _c('span', {
    staticClass: "text-danger h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()])]), _c('div', {
    staticClass: "border border-gray-20 bg-gray-10 rounded-8 p-3"
  }, [_c('div', {
    staticClass: "question-body",
    domProps: {
      "innerHTML": _vm._s(_vm.question.question)
    }
  }), _vm.questionHasRadioInput ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-form-radio', {
    staticClass: "question-radio cursor-pointer",
    attrs: {
      "id": `${_vm.question.uniqueName}-yes`,
      "name": _vm.question.unqiueName,
      "value": true
    },
    model: {
      value: _vm.value.booleanValue,
      callback: function ($$v) {
        _vm.$set(_vm.value, "booleanValue", $$v);
      },
      expression: "value.booleanValue"
    }
  }, [_vm._v("Yes")]), _c('b-form-radio', {
    staticClass: "mt-2 question-radio cursor-pointer",
    attrs: {
      "id": `${_vm.question.uniqueName}-no`,
      "name": _vm.question.unqiueName,
      "value": false
    },
    model: {
      value: _vm.value.booleanValue,
      callback: function ($$v) {
        _vm.$set(_vm.value, "booleanValue", $$v);
      },
      expression: "value.booleanValue"
    }
  }, [_vm._v("No")])], 1) : _vm._e(), _vm.questionHasSingleDropdown ? _c('div', [_vm.questionHasSingleDropdown ? _c('ba-dropdown', {
    staticClass: "bg-transparent",
    attrs: {
      "required": "",
      "value": _vm.value.dropDownValue,
      "label": "Options",
      "options": _vm.question.selectOptions
    },
    model: {
      value: _vm.value.dropDownValue,
      callback: function ($$v) {
        _vm.$set(_vm.value, "dropDownValue", $$v);
      },
      expression: "value.dropDownValue"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.questionHasTextarea ? _c('div', [_c('ba-textarea', {
    attrs: {
      "required": "",
      "classText": "white",
      "label": "Explanation",
      "rows": 3,
      "placeholder": "Explain your response"
    },
    model: {
      value: _vm.value.textValue,
      callback: function ($$v) {
        _vm.$set(_vm.value, "textValue", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "value.textValue"
    }
  })], 1) : _vm._e(), _vm.questionHasTextInput ? _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Explanation",
      "placeholder": "Explain your response",
      "classText": "white"
    },
    model: {
      value: _vm.value.stringValue,
      callback: function ($$v) {
        _vm.$set(_vm.value, "stringValue", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "value.stringValue"
    }
  })], 1) : _vm._e(), _vm.questionHasSupportingDocuments || true ? _c('div', [_c('ba-upload-field', {
    attrs: {
      "saved-files": _vm.value.documents,
      "loading": _vm.question.isUploading,
      "label": "Supporting document",
      "id": `${_vm.question.uniqueName}-${_vm.question.innerOrderIndex}`,
      "description": "Upload files"
    },
    on: {
      "input": function ($event) {
        return _vm.uploadFile($event, _vm.index);
      }
    }
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }