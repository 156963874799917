
import Vue from "vue";

export default Vue.extend({
    name: "ReportDraftModal",
    props: {
        regulator: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-report-draft");
        },
        goBackToReportPage() {
            this.closeModal();
            const { id } = this.$route.params;
            this.$router.replace({ name: "reports.view", params: { id } });
        },
    },
});
