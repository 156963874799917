
import Vue from "vue";
import ReportDraftModal from "@/app/components/modals/ReportDraftModal.vue";
import ReportSubmittedModal from "@/app/components/modals/ReportSubmittedModal.vue";
import ReassignReviewerModal from "@/app/components/modals/ReassignReviewerModal.vue";
import { formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";
import ReportService from "@/api/report-service";
import pencom from "@/assets/providers/pencom";
import cbn from "@/assets/providers/cbn";
import sec from "@/assets/providers/sec";
import ngx from "@/assets/providers/ngx";
import naicom from "@/assets/providers/naicom";
import SectorialRequirementInput from "@/app/components/reports/SectorialRequirementInput.vue";

export default Vue.extend({
    components: { ReportDraftModal, ReportSubmittedModal, ReassignReviewerModal, SectorialRequirementInput },
    data() {
        return {
            isLoading: false,
            report: null,
            DateTime,
            sectorialInputModels: [] as any[],
            numberOfCompletedNaicomAnswers: 0,
            numberOfCompletedSecAnswers: 0,
            numberOfCompletedNgxAnswers: 0,
            numberOfCompletedCbnAnswers: 0,
            numberOfCompletedPencomAnswers: 0,
            isSaving: false,
            reportReviewer: {},
        };
    },
    computed: {
        principles() {
            const { regulator } = this.$route.params;
            let principles = [];
            switch (regulator) {
                case "sec":
                    principles = sec;
                    break;
                case "naicom":
                    principles = naicom;
                    break;
                case "pencom":
                    principles = pencom;
                    break;
                case "ngx":
                    principles = ngx;
                    break;
                case "cbn":
                    principles = cbn;
                    break;
                default:
                    break;
            }
            return principles.map((principle: { questions: any[] }) => {
                return {
                    ...principle,
                    questions: principle.questions.map((question) => {
                        return {
                            ...question,
                            type: [question.dataType],
                            isUploading: false,
                            attachments: [],
                            dateValue: "",
                            stringValue: "",
                            dropDownValue: "",
                            documents: [],
                        };
                    }),
                };
            });
        },
        regulatorName(): { short: string; long: string; image: string } {
            const { regulator } = this.$route.params;

            const regulators: Record<string, { short: string; long: string; image: string }> = {
                pencom: { image: "submit-pencom", short: "PENCOM", long: "" },
                cbn: { image: "submit-cbn", short: "CBN", long: "Central Bank of Nigeria" },
                ngx: { image: "submit-cbn", short: "NGX", long: "Nigerian Exchange Group" },
                sec: { image: "submit-sec", short: "SEC", long: "Securities and Exchange Commission" },
                naicom: { image: "submit-naicom", short: "NAICOM", long: "National Insurance Commission" },
            };

            return regulators[regulator];
        },
        canSubmitForInternalReview(): boolean {
            const { regulator } = this.$route.params;
            if (regulator && regulator === "naicom") return this.numberOfCompletedNaicomAnswers >= 64;
            if (regulator && regulator === "sec") return this.numberOfCompletedSecAnswers >= 23;
            if (regulator && regulator === "ngx") return this.numberOfCompletedNgxAnswers >= 23;
            if (regulator && regulator === "cbn") return this.numberOfCompletedCbnAnswers >= 234;
            if (regulator && regulator === "pencom") return this.numberOfCompletedPencomAnswers >= 137;
            return false;
        },
    },
    methods: {
        formatDate,
        async fetchReportById() {
            const { id } = this.$route.params;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportById(id);
                this.report = res.data;
                this.sectorialInputModels = res.data.sectorialInputModels;
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },

        async saveSectorialRequirements(isSavingAsDraft = false) {
            this.isSaving = true;
            const { id: reportId, regulator: regulatorId } = this.$route.params;
            const body = { editStatus: isSavingAsDraft ? "Draft" : "SubmittedForInternalReview" };
            try {
                const response = await ReportService.setSectorialRequirementStatus({ reportId, regulatorId }, body);
                if (isSavingAsDraft) {
                    this.$bvModal.show("modal-report-draft");
                } else {
                    this.$bvModal.show("modal-report-submitted");
                    this.reportReviewer = { companyReviewerDetails: response.data.reviewer };
                }
                this.isSaving = false;
            } catch (error) {
                this.isSaving = false;
            }
        },

        updateNumberOfValidInputModels(count: number) {
            const { regulator } = this.$route.params;
            if (regulator === "naicom") this.numberOfCompletedNaicomAnswers += count;
            if (regulator === "sec") this.numberOfCompletedSecAnswers += count;
            if (regulator === "ngx") this.numberOfCompletedNgxAnswers += count;
            if (regulator === "cbn") this.numberOfCompletedCbnAnswers += count;
            if (regulator === "pencom") this.numberOfCompletedPencomAnswers += count;
        },
    },
    async mounted() {
        const { regulator } = this.$route.params;
        await this.fetchReportById();
        this.numberOfCompletedNaicomAnswers = this.sectorialInputModels.filter((model: any) => model.regulatorId === "naicom").length;
        this.numberOfCompletedSecAnswers = this.sectorialInputModels.filter((model: any) => model.regulatorId === "sec").length;
        this.numberOfCompletedNgxAnswers = this.sectorialInputModels.filter((model: any) => model.regulatorId === "ngx").length;
        this.numberOfCompletedCbnAnswers = this.sectorialInputModels.filter((model: any) => model.regulatorId === "cbn").length;
        this.numberOfCompletedPencomAnswers = this.sectorialInputModels.filter((model: any) => model.regulatorId === "pencom").length;
        this.reportReviewer = (this.report as any).sectorialRequirementsReviews.find((review: any) => review.regulatorId === regulator);
    },
});
