
import Vue from "vue";
import SectorialRequirementQuestion from "./SectorialRequirementQuestion.vue";
import ReportService from "../../../api/report-service";

type IPrincipleType = {
    name: string;
    title: string;
    description: string;
    hasFee: boolean;
    fee: number;
    normalizedName: string;
    isImportant: boolean;
    questions: [
        {
            question: string;
            isRequired: boolean;
            uniqueName: string;
            entryName: string;
            orderIndex: number;
            innerOrderIndex: number;
            dataType: number;
            selectOptions: [];
            allowedDocType: string[];
            isFlaggable: boolean;
            textValue: string;
        },
    ];
    orderIndex: number;
};

export default Vue.extend({
    props: {
        principle: {
            required: true,
            type: Object as () => IPrincipleType,
        },
        index: Number,
        sectorialInputModels: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    components: {
        SectorialRequirementQuestion,
    },
    data(): {
        isSaving: boolean;
        otherDocumentations: any[];
        answers: any[];
        haveSavedResponse: boolean;
    } {
        return {
            isSaving: false,
            otherDocumentations: [{ id: "1" }],
            answers: [],
            haveSavedResponse: false,
        };
    },
    computed: {
        formIsValid(): boolean {
            return this.answers.every((answer) => {
                if (answer.dataType === 9) return !!answer.textValue && answer.booleanValue !== null;
                if (answer.dataType === 15) return !!answer.textValue && !!answer.dropDownValue && answer.booleanValue !== null;
                if (answer.dataType === 16) return !!answer.textValue && !!answer.dropDownValue;
                return true;
            });
        },
    },
    methods: {
        async save(index: number) {
            const { id: reportId, regulator: regulatorId } = this.$route.params;

            const sectorialInputModels = this.answers.map((answer) => {
                return {
                    reportId,
                    regulatorId,
                    entry: answer.entry,
                    ...((answer.dataType === 9 || answer.dataType === 15) && { booleanValue: answer.booleanValue }),
                    ...((answer.dataType === 16 || answer.dataType === 15) && { dropDownValue: answer.dropDownValue }),
                    ...((answer.dataType === 9 || answer.dataType === 16 || answer.dataType === 15) && { textValue: answer.textValue }),
                    ...(answer.documents &&
                        answer.documents.length > 0 && {
                            documents: answer.documents.map(({ fileName, size, docUploadId }: any) => {
                                return { docName: fileName, docSize: size, docId: docUploadId };
                            }),
                        }),
                };
            });

            const body = { sectorialInputModels };

            this.isSaving = true;
            try {
                const res = await ReportService.submitSectorialRequirementInputs(reportId, body);
                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                if (!this.haveSavedResponse) {
                    this.haveSavedResponse = true;
                    this.$emit("have-saved-response", sectorialInputModels.length);
                }
                this.$root.$emit("bv::toggle::collapse", `principle-${index}`);
                this.$root.$emit("bv::toggle::collapse", `principle-${index + 1}`);
                this.isSaving = false;
            } catch (error) {
                this.isSaving = false;
            }
        },
        setupForm() {
            const { regulator: regulatorId } = this.$route.params;

            const document: any = this.sectorialInputModels.find((model: any) => model.regulatorId === regulatorId && model.entry === "documentation");
            if (document) {
                this.otherDocumentations = document.documents.map(({ docSize, docId, docName }: any) => {
                    return { id: Math.random().toString(36).substring(7), fileName: docName, docUploadId: docId, size: docSize };
                });
            }

            this.answers = this.principle.questions.map((question) => {
                const answer: any = this.sectorialInputModels.find((model: any) => model.regulatorId === regulatorId && model.entry === question.entryName);
                return {
                    name: question.uniqueName,
                    entry: question.entryName,
                    dataType: question.dataType,
                    textValue: answer?.textValue ?? "",
                    dropDownValue: answer?.dropDownValue ?? "",
                    booleanValue: answer?.booleanValue ?? null,
                    documents: [],
                    ...(answer &&
                        answer.documents &&
                        answer.documents.length > 0 && {
                            documents: answer.documents.map(({ docSize, docId, docName }: any) => {
                                return { fileName: docName, docUploadId: docId, size: docSize };
                            }),
                        }),
                };
            });
            if (this.formIsValid) this.haveSavedResponse = true;
        },
        updateInput(input: any) {
            console.log("Input from Sectorial question", input);
        },
        addDocument() {
            this.otherDocumentations.push({ id: Math.random().toString(36).substring(7) });
        },
        async uploadFile(payload: any, index: any) {
            if (payload.length === 1) {
                this.otherDocumentations[index] = { id: this.otherDocumentations[index].id, ...payload[0] } as { id: string };
            } else {
                this.otherDocumentations = this.otherDocumentations.filter((_, docIndex) => docIndex !== index);
            }
            const documents = this.otherDocumentations.map(({ fileName, size, docUploadId }: any) => {
                return { docName: fileName, docSize: size, docId: docUploadId };
            });
            const { id: reportId, regulator: regulatorId } = this.$route.params;
            const sectorialInputModels = [{ reportId, regulatorId, entry: "documentation", documents }];
            const body = { sectorialInputModels };
            try {
                await ReportService.submitSectorialRequirementInputs(reportId, body);
            } catch (error) {
                //
            }
        },
    },
    mounted() {
        this.setupForm();
    },
});
