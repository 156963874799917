var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "report-draft",
      "size": "md"
    }
  }, [_c('div', {
    staticClass: "report-draft d-flex flex-column align-items-center mx-auto justify-content-center text-center"
  }, [_c('div', {
    staticClass: "report-draft__content d-flex flex-column"
  }, [_c('BaModalIcon', {
    attrs: {
      "variant": "draft",
      "icon": "info"
    }
  }), _c('h4', {
    staticClass: "mb-0 report-draft__title text-black-100"
  }, [_vm._v("Draft saved")]), _c('p', {
    staticClass: "mb-0 report-draft__message text-black-60 mx-auto"
  }, [_vm._v("The draft report according to the sectorial requirements of " + _vm._s(_vm.regulator.short) + " has been saved.")])], 1), _c('BaButton', {
    staticClass: "border-0 text-white bg-gray-dark w-100 text-center justify-content-center",
    attrs: {
      "text": "Continue"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.goBackToReportPage.apply(null, arguments);
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }