var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "is-loading": _vm.isLoading
    }
  }, [_vm.report ? _c('div', {
    staticClass: "container-fluid-custom mt-body py-6 submit-report"
  }, [_c('BaBreadcrumb', {
    attrs: {
      "back-text": "Back",
      "text-one": "Dashboard",
      "text-two": "Submit Report"
    }
  }), _c('div', {
    staticClass: "submit-report__header bg-gray-20 d-flex align-items-center justify-content-between flex-wrap"
  }, [_c('div', {
    staticClass: "submit-report__regulator d-flex align-items-center flex-wrap"
  }, [_c('img', {
    staticClass: "submit-report__regulator__image",
    attrs: {
      "src": require(`@/assets/pngs/${_vm.regulatorName.image}.png`)
    }
  }), _c('div', {
    staticClass: "submit-report__regulator__text d-flex flex-column"
  }, [_c('h5', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v("Submit to " + _vm._s(_vm.regulatorName.short))]), _c('p', {
    staticClass: "mb-0 text-black-70"
  }, [_vm._v("Complete the sectorial requirements to submit to " + _vm._s(_vm.regulatorName.short))])])]), _c('BaButton', {
    staticClass: "text-white bg-gray-dark",
    attrs: {
      "text": "Save and continue later",
      "loading": _vm.isSaving,
      "disabled": _vm.isSaving
    },
    on: {
      "click": function ($event) {
        return _vm.saveSectorialRequirements(true);
      }
    }
  })], 1), _c('div', {
    staticClass: "submit-report__body"
  }, [_c('div', {
    staticClass: "submit-report__body--left d-flex flex-column"
  }, [_c('div', {
    staticClass: "submit-report__information d-flex flex-column"
  }, [_c('div', {
    staticClass: "submit-report__information-text d-flex flex-column"
  }, [_c('span', {
    staticClass: "text-black-70"
  }, [_vm._v("Report ID")]), _c('p', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v(_vm._s(_vm.report.reportInformation.reportCode))])]), _c('div', {
    staticClass: "submit-report__information-text d-flex flex-column"
  }, [_c('span', {
    staticClass: "text-black-70"
  }, [_vm._v("Report Timeline")]), _c('p', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v(" " + _vm._s(_vm.DateTime.fromISO(_vm.report.reportInformation.reportingTimelineFrom).toFormat("LLL d, yyyy")) + " - " + _vm._s(_vm.DateTime.fromISO(_vm.report.reportInformation.reportingTimelineTo).toFormat("LLL d, yyyy")) + " ")])]), _c('div', {
    staticClass: "submit-report__information-text d-flex flex-column"
  }, [_c('span', {
    staticClass: "text-black-70"
  }, [_vm._v("Date Submitted to FRC")]), _c('p', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v("19/11/2022, 2:19PM")])])]), _c('b-badge', {
    staticClass: "w-100 py-2 bg-yellow-10 submit-report__pill d-flex align-items-center text-center"
  }, [_c('span', {
    staticClass: "text-green-50 font-italic"
  }, [_vm._v("New")]), _c('span', {
    staticClass: "text-yellow-150"
  }, [_vm._v("Sectorial Requirements (" + _vm._s(_vm.regulatorName.short) + ")")])])], 1), _c('div', {
    staticClass: "submit-report__body--right"
  }, [_c('div', {
    staticClass: "submit-report__principles"
  }, [_c('div', {
    staticClass: "submit-report__principles__header d-flex flex-column"
  }, [_c('h5', {
    staticClass: "mb-0 text-black-90"
  }, [_vm._v("Sectorial Requirements")]), _c('p', {
    staticClass: "mb-0 text-black-70"
  }, [_vm._v(" Respond to various questions and comments regarding your Company's compliance with the sectorial requirements of the " + _vm._s(_vm.regulatorName.long) + " (" + _vm._s(_vm.regulatorName.short) + "). ")])]), _c('section', {
    staticClass: "submit-report__principles__body"
  }, [_vm._l(_vm.principles, function (principle, index) {
    return _c('SectorialRequirementInput', {
      key: index,
      attrs: {
        "principle": principle,
        "index": index,
        "sectorial-input-models": _vm.sectorialInputModels
      },
      on: {
        "have-saved-response": _vm.updateNumberOfValidInputModels
      }
    });
  }), _c('div', {
    staticClass: "submit-report__buttons d-flex justify-content-center"
  }, [_c('BaButton', {
    staticClass: "border-0 submit-report__button submit-report__button--back",
    attrs: {
      "text": "Back"
    }
  }), _c('BaButton', {
    staticClass: "border-0 submit-report__button submit-report__button--submit",
    attrs: {
      "text": "Submit for review",
      "loading": _vm.isSaving,
      "disabled": !_vm.canSubmitForInternalReview || _vm.isSaving
    },
    on: {
      "click": function ($event) {
        return _vm.saveSectorialRequirements(false);
      }
    }
  })], 1)], 2)])])]), _c('ReportDraftModal', {
    attrs: {
      "regulator": _vm.regulatorName
    }
  }), _c('ReportSubmittedModal', {
    attrs: {
      "reviewer": _vm.reportReviewer
    }
  }), _c('ReassignReviewerModal', {
    attrs: {
      "report-id": _vm.$route.params.id,
      "report": _vm.report,
      "current-reviewer": _vm.reportReviewer,
      "usage": "others"
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }