var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "report-submitted",
      "size": "md"
    }
  }, [_c('div', {
    staticClass: "report-submitted d-flex flex-column align-items-center mx-auto justify-content-center text-center"
  }, [_c('div', {
    staticClass: "report-submitted__content d-flex flex-column"
  }, [_c('BaModalIcon', {
    attrs: {
      "variant": "success",
      "icon": "success"
    }
  }), _c('h4', {
    staticClass: "mb-0 report-submitted__title text-black-100"
  }, [_vm._v("Report Submitted")]), _c('p', {
    staticClass: "mb-0 report-submitted__message text-black-60 mx-auto"
  }, [_vm._v("This report has been submitted to the original report reviewer for internal review.")])], 1), _c('div', {
    staticClass: "report-submitted__footer d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "report-submitted__reviewer d-flex flex-column justify-content-center align-items-center"
  }, [_c('p', {
    staticClass: "mb-0 text-black-70"
  }, [_vm._v("Reviewer")]), _c('BaUser', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _c('div', {
    staticClass: "report-submitted__actions d-flex align-items-center justify-content-between"
  }, [_c('BaButton', {
    staticClass: "justify-content-center border-0 w-100 text-center report-submitted__button--reassign",
    attrs: {
      "variant": "none",
      "text": "Reassign Reviewer"
    },
    on: {
      "click": _vm.reassignReviewer
    }
  }), _c('BaButton', {
    staticClass: "justify-content-center border-0 w-100 text-center report-submitted__button--continue",
    attrs: {
      "variant": "none",
      "text": "Continue"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.goBackToReportPage.apply(null, arguments);
      }
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }